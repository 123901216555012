import * as React from "react"
import './index.scss';
import TwitterIcon from '../icons/twitter.js';
import LinkedInIcon from '../icons/linkedin.js';
import GithubIcon from '../icons/github.js';
import MailIcon from '../icons/mail.js';

const SocialGrid = (props) => (
  <div className="social-grid">
    {props.socials.map(social => (
      <a key={social.text} className={`cell ${social.className}`} href={social.link}>
        {social.icon != null && social.icon}
        {/* <span>{social.text}</span> */}
      </a>
    ))}
  </div>
)

const IndexPage = () => {
  const generateColor = (hue) => `hsl(${hue}deg, 40%, 60%)`;

  const socials = [
    { text: 'Twitter', icon: <TwitterIcon />, className: 'cell--twitter', link: 'https://twitter.com/samuelsital' },
    { text: 'LinkedIn', icon: <LinkedInIcon />, className: 'cell--linkedin', link: 'https://www.linkedin.com/in/samuelsital' },
    { text: 'Github', icon: <GithubIcon />, className: 'cell--github', link: 'https://github.com/samuelsital' },
    { text: 'Mail', icon: <MailIcon />, className: 'cell--mail', link: 'mailto:samuelsital@gmail.com' },
  ];

  return (
    <main className="main">
      <div className="bg" />
      <div className="main__title">
        Samuel Sital
      </div>
      <div className="main__subtitle">
        I love to design user interfaces and build cloud applications
      </div>
      <SocialGrid socials={socials} />
    </main>
  )
}

export default IndexPage
